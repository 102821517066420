window.initiate_hcaptcha = function () {
    document.querySelectorAll("form.wp-block-starter-form").forEach(current_form => {
        var captcha_container = 'captcha-' + current_form.getAttribute('id');
        var widgetID = hcaptcha.render(captcha_container, {
            sitekey: 'cf278f15-5528-4f90-8831-413efac27fc6',
            size: "invisible",
            callback: 'onSubmit'
        });
        current_form.setAttribute('data-widget-id', widgetID);
    });
};
window.onSubmit = function () {
    var formData = new FormData(document.getElementById(formID));
    formData.append('action', 'formulaireStarter');
    var inputs_files = document.querySelectorAll('#' + formID + ' input[type="file"]');
    if (inputs_files) {
        inputs_files.forEach(function (input_file) {
            var file = input_file.files[0];
            formData.append(input_file.getAttribute('name'), file);
        });
    }
    document.getElementById(formID).classList.add('done');
    document.getElementById('submit-' + formID).classList.add('d-none');
    fetch(ajaxurl['ajax_url'], {
        method: 'POST',
        body: formData,
    }).then(function (response) {
        if (response.ok) {
            document.getElementById('fail-' + formID).classList.add('d-none');
            document.getElementById('done-' + formID).classList.remove('d-none');
        } else {
            document.getElementById('fail-' + formID).classList.remove('d-none');
            document.getElementById('submit-' + formID).classList.remove('d-none');
        }
    });
}

function insert_hcaptcha_script() {
    const footer = document.getElementsByTagName('footer');
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.hcaptcha.com/1/api.js?onload=initiate_hcaptcha&render=explicit';
    script.setAttribute("async", "");
    script.setAttribute("defer", "");
    footer[0].after(script);
}

function updateCheckboxRequired(groupName) {
    var checkboxes = document.querySelectorAll('input[name="' + groupName + '"]');
    var checkedCheckboxExists = false;

    //On regarde sur le groupe si au moins une checkbox est checked
    for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
            checkedCheckboxExists = true;
            break;
        }
    }

    //On met à jour les autres checkbox pour retirer ou ajouter le required et le message d'erreur customisé
    for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].required = !checkedCheckboxExists;
        if (checkboxes[i].required) {
            checkboxes[i].setCustomValidity('Veuillez cocher au moins une case si vous souhaitez continuer.');
        } else {
            checkboxes[i].setCustomValidity('');
        }
    }
}

function hasOptionWithDataValue(select) {
    for (let i = 0; i < select.options.length; i++) {
        if (select.options[i].getAttribute("data-clientID") != '') {
            return true;
        }
    }
    return false;
}

window.addEventListener('DOMContentLoaded', (event) => {
    let first_interaction = true;
    let inputs = document.querySelectorAll('form.wp-block-starter-form input');
    if (inputs.length > 0) {
        inputs.forEach((item) => {
            item.addEventListener("click", (event) => {
                if (first_interaction) {
                    first_interaction = false;
                    insert_hcaptcha_script();
                }
            });
            item.addEventListener("focus", (event) => {
                if (first_interaction) {
                    first_interaction = false;
                    insert_hcaptcha_script();
                }
            });
        });
    }

    //Ajoute le required pour les listes qui sont en obligatoire
    const inputsRequired = document.querySelectorAll('div[data-required="true"] input');
    inputsRequired.forEach((input) => {
        input.required = true;
    });

    const selectsRequired = document.querySelectorAll('div[data-required="true"] select');
    selectsRequired.forEach((select) => {
        select.required = true;
    });

    //Modification des names pour les checkbox/radio pour un formulaire plus propre + onchange des required des checkbox
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const formFlexible = document.querySelectorAll("form.wp-block-starter-form")
    if (formFlexible.length > 0) {
        checkboxes.forEach((checkbox) => {
            let labelCheckbox = checkbox.parentNode.parentNode.previousElementSibling;
            if (labelCheckbox.tagName !== 'LABEL') labelCheckbox = labelCheckbox.previousElementSibling;
            if (labelCheckbox.tagName === 'LABEL') {
                const labelValue = labelCheckbox.innerText;
                checkbox.name = labelValue + '[]';
            }
            //Si la checkbox est required, on met en place la vérification par groupe
            if (checkbox.required) {
                checkbox.setCustomValidity('Veuillez cocher au moins une case si vous souhaitez continuer.');
                checkbox.addEventListener('change', function () {
                    updateCheckboxRequired(checkbox.name);
                });
            }
        });
    }

    const radios = document.querySelectorAll('input[type="radio"]');

    radios.forEach((radio) => {
        const parent = radio.parentNode.parentNode;

        if (parent && parent.previousElementSibling) {
            let labelRadio = parent.previousElementSibling;

            if (labelRadio.tagName !== 'LABEL') {
                labelRadio = labelRadio.previousElementSibling;
            }

            if (labelRadio && labelRadio.tagName === 'LABEL') {
                const labelValue = labelRadio.innerText;
                radio.name = labelValue + '[]';
            }
        }
    });


    document.querySelectorAll("select.wp-block-starter-select").forEach(current_select => {
        //Modification du clientID si choix d'une option avec une adresse mail définie
        current_select.addEventListener('change', function (event) {
            const selectedOption = current_select.selectedOptions[0];
            const clientID = selectedOption.getAttribute("data-clientID");
            if (clientID) {
                var form = current_select.closest('form');
                var inputClientID = form.querySelector('input[name="clientID"]');
                inputClientID.setAttribute('value', clientID);
            }
        });

        //On ajoute la clientID par défaut des options qui n'en ont pas et qui font parti d'un select qui ont des options avec un clientID spéficié.
        if (hasOptionWithDataValue(current_select)) {
            for (let i = 0; i < current_select.options.length; i++) {
                var option = current_select.options[i];
                const form = current_select.closest('form');
                const clientID = form.querySelector('input[name="clientID"]').value;
                if (option.getAttribute("data-clientID") === "") {
                    // Modifiez la valeur de data-clientID pour les options vides
                    option.setAttribute("data-clientID", clientID);
                }
            }
        }
    });

    document.querySelectorAll("form.wp-block-starter-form").forEach(current_form => {
        current_form.addEventListener('submit', function (event) {
            event.preventDefault();
            formID = current_form.getAttribute('id');
            widgetID = current_form.getAttribute('data-widget-id');
            hcaptcha.execute(widgetID);
        });
    });

});

